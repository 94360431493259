<template>
    <user-dashboard-layout>
        <v-container>
            <v-card class="mx-10 my-10">
                <v-card-title>
                    {{video.name}}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col md="9" sm="12">
                            <video id="player" playsinline controls :data-poster="video.thumbnail_url">
                                <source :src="videoUrl" type="video/mp4" />
                            </video>
                        </v-col>
                        <v-col  md="3" sm="12">
                            <v-row>
                                <v-col>
                                    <v-btn @click="incrementDownloads" target="_blank">{{$t('videos.' + videos.btndg)}}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        v-if="!personalizedVideoUrl && video.outro_exists"
                                        :loading="loading"
                                        @click="handleDownloadPersonalized">
                                        {{$t('videos.' + billings.btndp)}}
                                    </v-btn>
                                    <p v-else v-text="'Thanks for your patience, your video is currently being personalised'"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn v-if="personalizedVideoUrl" :href="personalizedVideoUrl" target="_blank" v-text="'Download'"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <code ref="iframeCode">
                                        {{iframeCode}}
                                    </code>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn @click="copyIframe">{{$t('videos.' + videos.btnc)}}</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import Plyr from 'plyr';
import Video from "../../../models/Video";
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import KonvaHelper from "../../../helpers/KonvaHelper";
import AxiosHelper from "../../../helpers/AxiosHelper";
import User from "../../../models/User";
import videos from "@/configs/videos";
// import Konva from 'konva';

export default {
    name: "Show",
    components: {UserDashboardLayout},
    data: function () {
        return {
            video: {},
            videoUrl: null,
            loading: false,
            personalizedVideoUrl: null,
            personalization: null,
            editMode: false,
            configKonva: {
                width: 1024,
                height: 768
            },
            logoConfig: {
                x: 500,
                y: 130,
                width: 160,
                height: 160,
                image: null,
                draggable: true,
                name: "logo",
            },
            photoConfig: {
                x: 700,
                y: 130,
                width: 160,
                height: 160,
                image: null,
                draggable: true,
                name: "photo",
            },
            companyConfig: {
                x: 500,
                y: 350,
                fontSize: 21,
                width: 300,
                height: 60,
                text: 'blabla inc.',
                name: "company",
                draggable: true,
            },
            fullNameConfig: {
                x: 500,
                y: 310,
                fontSize: 21,
                width: 300,
                height: 60,
                text: 'blabla inc.',
                name: "full_name",
                draggable: true,
            },
            titleConfig: {
                x: 500,
                y: 400,
                fontSize: 20,
                width: 200,
                height: 60,
                text: 'blabla inc.',
                name: "title",
                draggable: true,
            },
            registrationIdConfig: {
                x: 500,
                y: 450,
                fontSize: 20,
                width: 200,
                height: 60,
                text: 'blabla inc.',
                name: "registrationId",
                draggable: true,
            },
            emailConfig: {
                x: 500,
                y: 500,
                fontSize: 20,
                width: 200,
                height: 60,
                text: 'blabla inc.',
                name: "email",
                draggable: true,
            },
            websiteConfig: {
                x: 500,
                y: 550,
                fontSize: 16,
                width: 400,
                height: 60,
                text: 'blabla inc.',
                name: "website",
                draggable: true,
            },
            selectedShapeName: '',
            videos
        }
    },
    methods: {
        async incrementDownloads() {
            window.axios.post('/api/videos/' + this.video.id + '/increment-downloads')
            AxiosHelper.download(this.video.url)
        },
        async handleDownloadPersonalized() {
            this.loading = true
            await this.incrementDownloads()
            window.axios.post('/api/videos/' + this.video.id + '/personalized').then(res => {
                this.loading = false
                this.personalizedVideoUrl = res.data
                this.videoUrl = this.personalizedVideoUrl
            })
        },
        async initEditor() {
            this.editMode = true;

            let width = this.$refs.editorContainer.clientWidth;
            let logo = await KonvaHelper.loadImage(this.personalization.logo_url)
            let avatar = await KonvaHelper.loadImage(this.personalization.avatar_url)

            this.configKonva.width = width
            this.logoConfig.image = logo;
            this.photoConfig.image = avatar;

            this.companyConfig.text = this.personalization.company;
            this.fullNameConfig.text = this.personalization.name;
            this.titleConfig.text = this.personalization.job_title;
            this.registrationIdConfig.text = this.personalization.registered_id;
            this.emailConfig.text = this.personalization.email;
            this.websiteConfig.text = this.personalization.website;
        },
        handleTransformEnd(e) {
            this[this.selectedShapeName+'Config'] = KonvaHelper.handleTransformEnd(e, this[this.selectedShapeName+'Config'])
        },

        copyIframe() {
            const textToCopy = this.$refs.iframeCode
            const range = document.createRange();
            range.selectNode(textToCopy);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
        }
    },
    async mounted() {
        const videoId = this.$route.params.id
        this.video = await Video.find(videoId)
        this.videoUrl = this.video.url

        const user = await User.include(['personalization']).find(this.$auth.user().id)

        this.personalization = user.personalization;
        this.personalization.avatar_url = user.avatar_url;
        new Plyr('#player');
    },
    computed: {
        publicUrl() {
            const resolved = this.$router.resolve({
                name: 'videos.show',
                params: { id: this.video.id },
            });

            return window.location.origin + resolved.href
        },
        iframeCode() {
            return `<iframe width="480"
                        height="360"
                        scrolling="no"
                        style="overflow: hidden; border: none"
                        src="${this.publicUrl}"></iframe>`
        }
    }
}
</script>

<style lang="scss">
@import "~plyr/src/sass/plyr";

.border {
    border: 1px solid #728197;
}
</style>
